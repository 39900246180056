<template>
	<product-landing-page :page-name="pageName" />
</template>
<script>
import ProductLandingPage from './components/ProductLandingPage1';
import PublicStore from '@/mixins/PublicStore';

export default {
	name: 'BranLandingPage',
	components: {
		ProductLandingPage,
	},
	mixins: [PublicStore],
	data() {
		return {
			pageName: this.$route.fullPath.split('/')[2],
		};
	},
	created() {
		if (!this.showPublicStore) {
			this.$router.replace({ name: 'Store' }).catch(() => {});
		}
	},
};
</script>
